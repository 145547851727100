<template>
    <div class="app-sidebar">
        <!-- Navbar logo -->
            <div class="logo">
                <a href="javascript:void(0);" class="logo-icon"></a>
            </div>
            <div class="app-menu">
                <ul class="accordion-menu">

                    <!-- Navar title -->
                    <li class="sidebar-title">
                        {{$t('navigation.side.navigation')}}
                    </li>
                    <router-link custom to="/" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'active-page']" id="dashboard">
                            <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i class="material-icons-two-tone">dashboard</i>Claims
                            </a>
                        </li>
                    </router-link>

                </ul>
            </div>
        </div>
</template>

<script>
import UserService from '../../services/user.service';
import Multiselect from 'vue-multiselect';

export default {
    name: 'Sidebar',
    components: {
        Multiselect
    },
    mounted() {
        this.clients = this.$store.state.auth.user.clients;
        let selectedClients = this.clients.filter((client) => {
            return parseInt(client.id) == parseInt(this.selected_client);
        });
        this.selectedClient = selectedClients[0];
    },
    data() {
        return {
            clients: [],
            favourites: [],
            selectedClient: null,
            night: this.$store.state.auth.status.dark
        }
    },
    methods: {
        addToFavourite(id) {
            const item = document.getElementById(id);
            const list = document.getElementById('fav-list');
            console.log(list);
            list.appendChild(item);
        },
        selectClient(){
            UserService.selectClient(this.selectedClient || this.clients[0]).then(() => {
                this.$store.dispatch('auth/selectClient', this.selectedClient).then(() => {
                    this.$router.go(this.$router.currentRoute);
                })
            })
            .catch(error => {
                // Handle error
                // console.error(error);
            });
            
        }
    },
    computed: {
        selected_client: function() {
            return this.$store.state.auth.user.selectedClient;
        }
    }
}
</script>

<style scoped>
/* Sidebar title */
.logo-icon {
    width: 100% !important;
    height: 50px !important;
    background-size: 66% !important;
    border-radius: 0px !important;
    background-position: center !important;
}

.hidden-sidebar-logo .logo-icon {
    background-size: 50% !important;
    height: 44.4px !important;
}

.no-fav {
    margin: 3px 15px;
    padding: 10px 15px;
    color: var(--font-color-secondary);
    text-align: center;
}

/* .no-fav-icon {
    margin-right: 13px;
    vertical-align: top;
    line-height: 20px;
} */

.add-to-favourite {
    padding: 0 10px;
    filter: none;
    color: #6d7b91;
    font-size: 19px;
}

.add-to-favourite:hover {
    cursor: pointer;
    color: var(--font-color-primary);
}

.favourite {
    display: none;
}

/* .app-menu>ul>li.active-page>a:hover .favourite{
    display: block;
}

.favourite:hover {
    color: #fff!important;
} */

.has-sub-menu:hover {
    display: none;
}
</style>